<template>
    <div class="add-goods-page">
        <div class="title">
            <span>{{ !$route.query.id ? '添加' : '编辑' }}商品</span>
        </div>
        <div>
            <el-form :model="addGoodsData" ref="addGoodsForm" status-icon :rules="rules" label-width="86px">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="addGoodsData.title" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="副标题">
                    <el-input v-model="addGoodsData.subtitle" size="medium" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="商品分组" prop="good_group_id"
                    :rules="[{ required: true, message: '请选择分组', trigger: 'change' }]">
                    <el-select v-model="addGoodsData.good_group_id" size="medium" clearable
                        placeholder="请选择">
                        <el-option v-for="item in goodsGroupList" :key="item.group_id" :label="item.group_name"
                            :value="item.group_id">
                        </el-option>
                    </el-select>
                    <el-button style="margin-left: 10px" type="primary" size="small" @click="addGoodsGroupDialog = true">
                        添加新商品分组</el-button>
                </el-form-item>
                <el-form-item label="商品分类" prop="faction"
                    :rules="[{ required: true, message: '请选择分类', trigger: 'change' }]">
                    <el-select v-model="addGoodsData.faction" size="medium" clearable multiple collapse-tags
                        placeholder="请选择">
                        <el-option v-for="item in factionList" :key="item.faction_id" :label="item.faction_name"
                            :value="item.faction_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="库存">
                    <el-input-number v-model="addGoodsData.inventory" :min="0" :max="1000000" size="medium"
                        autocomplete="off"></el-input-number>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input-number v-model="addGoodsData.price" :min="0" :max="1000000" size="medium"
                        autocomplete="off"></el-input-number>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="成本">
                    <el-input-number v-model="addGoodsData.cost_price" :min="0" :max="1000000" size="medium"
                        autocomplete="off"></el-input-number>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>
                <el-form-item label="图片" style="margin-bottom: 10px;">
                    <el-upload class="avatar-uploader" action="/api/merchants/api/v2/update/file" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="addGoodsData.images" :src="addGoodsData.images" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <!--多个规格-->
                <div>
                    <el-form-item label="商品规格">
                        <div class="shop-sku">
                            <div class="sku-list-item" v-for="(item, index) in spuList" :key="index">
                                <div class="item-header">
                                    <span>规格名称</span>
                                    <el-input size="small" v-model="item.spu_name" :maxlength="8"></el-input>
                                    <i v-if="index === 0" style="margin-left: 5px;cursor: pointer;"
                                        class="iconfont icon-questionfill" @click="goExample(3)"></i>
                                    <div class="right-btn">
                                        <el-button @click="deleteSku(index)" plain size="small" type="text">
                                            <i class="el-icon-delete"></i>移除
                                        </el-button>
                                    </div>
                                </div>
                                <div class="child-list">
                                    <el-tag v-for="(tag) in item.spu_detail" closable size="small" :key="tag.spu_detail_id"
                                        :disable-transitions="false" type="info" @close="handleClose(tag, index)">
                                        {{ tag.spu_detail_name }}
                                    </el-tag>
                                    <el-input class="input-new-tag" v-model="inputValue" v-if="skuIndex === index"
                                        :ref="`saveTagInput${index}`" size="mini"
                                        @keyup.enter.native="handleInputConfirm(index)" @focus="skuInputFocus(index)">
                                    </el-input>
                                    <el-input class="input-new-tag" v-if="skuIndex !== index"
                                        :ref="`saveTagInput${index}`" size="mini"
                                        @keyup.enter.native="handleInputConfirm(index)" @focus="skuInputFocus(index)">
                                    </el-input>
                                    <el-button class="button-new-tag" type="primary" size="mini"
                                        @click="showInput(index)">添加</el-button>
                                </div>
                            </div>
                            <div @click="addSpu" class="add-sku">
                                <i style="font-size: 30px" class="iconfont icon-tianjia"></i>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="规格设置" v-if="skuList.length > 0">
                        <template>
                            <el-table class="multiple-table" ref="multipleTable" header-row-class-name="table-header"
                                :data="skuList">
                                <el-table-column v-for="(item, index) in spuList" :key="item.spu_id"
                                    :label="item.spu_name">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.combination_name && scope.row.combination_name.split('_')[index] }}</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="combination_name"
                                    label="规格">
                                </el-table-column> -->
                                <el-table-column prop="price" min-width="110">
                                    <template slot="header">
                                        <span><span style="color:#f5222d;">*</span>价格(分)</span> 
                                        <el-button type="text" @click="showEditDialog(1)">批量编辑</el-button>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-input-number size="small" style="width: 100%;" v-model="scope.row.price"
                                            :controls="false" :min="0" :step="1"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="price" min-width="110">
                                    <template slot="header">
                                        <span><span style="color:#f5222d;">*</span>成本(分)</span>
                                        <el-button type="text" @click="showEditDialog(2)">批量编辑</el-button>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-input-number size="small" style="width: 100%;" v-model="scope.row.cost_price"
                                            :controls="false" :min="0" :step="1"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="inventory" label="库存" min-width="110">
                                    <template slot="header">
                                        <span><span style="color:#f5222d;">*</span>库存</span>
                                        <el-button type="text" @click="showEditDialog(3)">批量编辑</el-button>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-input-number size="small" style="width: 100%;" @change="skuInventoryChange" v-model="scope.row.inventory"
                                            :controls="false" :min="0"></el-input-number>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-form-item>
                </div>
                <el-form-item label="详情">
                    <wangEditor v-if="showEditor" v-model="addGoodsData.details" style="width: 600px;"></wangEditor>
                </el-form-item>
            </el-form>
            <div style="padding: 14px 80px;">
                <el-button type="primary" size="small" @click="submitGoods('addGoodsForm')"> 保 存 </el-button>
            </div>
        </div>

        <el-dialog :title="`新增商品分组`" class="add-dialog" :close-on-click-modal="false" width="400px"
            :visible.sync="addGoodsGroupDialog">
            <el-form :model="addGoodsGroupData" ref="addGoodsGroupForm" label-width="78px">
                <el-form-item label="分组名称" prop='groupName'
                    :rules="[{ required: true, message: '请输入名称', trigger: 'change' }]">
                    <el-input v-model="addGoodsGroupData.groupName" size="medium"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="addGoodsGroupDialog = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="sureSaveGoodsGroup('addGoodsGroupForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog width="400px" :close-on-click-modal="false" :title="`批量编辑${editType === 1 ? '价格' : editType === 2 ? '成本' : '库存'}`" :visible.sync="editDialog">
            <el-form>
                <el-form-item :label="editType === 1 ? '价格' : editType === 2 ? '成本' : '库存'">
                    <el-input-number size="small" v-model="editNum"
                                :controls="false" :min="0"></el-input-number>
                </el-form-item>
                <div class="note" style="margin-top: -16px; margin-left: 38px;">批量编辑{{editType === 1 ? '价格' : editType === 2 ? '成本' : '库存'}}后，将覆盖所有规格的{{editType === 1 ? '价格' : editType === 2 ? '成本' : '库存'}}</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">取 消</el-button>
                <el-button type="primary" @click="confirmEdit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import wangEditor from '@/components/wangEditor.vue'
// import { uuid } from 'uuid'
import { v4 as uuidv4 } from 'uuid'
const uuid =require('uuid');
export default {
    name: 'AddGoods',
    components: { wangEditor },
    data() {
        return {
            // 验证规则
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ]
            },

            // 增加/编辑商品业务数据
            addGoodsData: {
                good_id: uuid.v1(),
                title: '',
                subtitle: '',
                images: '',
                details: '',
                inventory: '',
                price: '',
                cost_price: '',
                good_group_id: '',
                good_group_name: '',
                faction: []
            },
            showEditor: false,

            // 单个规格
            singleTable: [{}],
            giftList: [],

            // sku 规格数组
            spuList: [],
            // 运费模板列表
            freightList: [],

            skuList: [],
            inputValue: '',
            skuIndex: '',
            oldSkuSetTableList: [],
            selectedSkuSetList: [],
            isShowSkuSet: false,
            skuTemplateList: [],
            templateDialog: false,
            templateName: '',
            templateObj: {},
            selectTempIndex: 0,
            // 选择删除模板Index
            showTempIndex: null,

            factionList: [],
            goodsGroupList: [],
            addGoodsGroupDialog: false,
            // 新增/编辑数据
            addGoodsGroupData: {
                groupName: ''
            },

            editNum: 0,
            editDialog: false,
            editType: 1
        }
    },
    mounted() {
        this.getGoodsGroupList()
        this.getGoodsTypeList()
        // 取参为字符串
        if (this.$route.query.id) {
            this.getGoodsDetail(this.$route.query.id)
        } else {
            this.showEditor = true;
        }
    },
    methods: {
        /**
         * 获取商品详情
         * @param {string} goodsId 商品ID
         */
        getGoodsDetail(goodsId){
            this.axios.post('/good/query/one', {
                good_id: goodsId
            }).then((res) => {
                const { title, subtitle, images, details, price, inventory, cost_price, good_id, good_group_id, faction, good_group_name, spu, sku } = { ...res.data }
                this.addGoodsData = { title, subtitle, images, details, price, inventory, cost_price, good_group_id, faction, good_group_name, spu, sku }
                this.spuList = this.addGoodsData.spu || []
                this.skuList = this.addGoodsData.sku || []
                this.addGoodsData.good_id = good_id
                this.showEditor = true;
            })
        },

        // 上传图片成功回调
        handleAvatarSuccess(res, file) {
            this.addGoodsData.images = res.data.url
        },
        // 图片上传前验证，图片格式、大小等
        beforeAvatarUpload(file) {
            console.log(file.type)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },

        // 规格里sku改变，修改外部总库存
        skuInventoryChange(){
            let inventory = 0
            this.skuList.forEach(item => {
                inventory += item.inventory
            })
            this.addGoodsData.inventory = inventory
        },

        // 新增SKU
        addSpu() {
            this.isShowSkuSet = true;

            let len = this.spuList.length;
            if (len >= 4) {
                this.$message.error('最多添加4种商品规格')
            } else {
                this.spuList.push({
                    spu_name: '',
                    good_id: this.addGoodsData.good_id,
                    spu_id: uuid.v1(),
                    spu_detail: [],
                })
            }
        },

        // 选择模板
        // selectSkuTemplate(index) {
        //     this.selectTempIndex = index;
        // },
        // skuTemplateChange(val, index) {
        //     if (val) {
        //         if (this.showTempIndex) this.skuTemplateList[this.showTempIndex].delTempVisible = false;
        //         let templateObj = this.skuTemplateList.filter((item) => {
        //             return item.templateName === val;
        //         })[0];

        //         let keyList = Object.keys(templateObj);
        //         let skuField = '';
        //         keyList.forEach((fieldItem) => {
        //             if (fieldItem.indexOf('sku') !== -1) {
        //                 skuField = fieldItem;
        //             }
        //         });

        //         this.spuList[index].spu_detail = [...templateObj.spu_detail];
        //         this.spuList[index]['sku' + index] = templateObj[skuField];
        //         this.spuList[index].templateName = templateObj.templateName;

        //         // this.getSkuSetTableList();
        //         this.skuList = [...this.doExchange(this.spuList, [], 0)];

        //         // 和原来的组合数据比较，如果组合相同，则保留原来填写的数据
        //         this.oldSkuSetTableList.forEach((oldItem) => {
        //             this.skuList = this.skuList.map((item) => {
        //                 if (this.isObjectValueEqual(oldItem, item)) {
        //                     return oldItem
        //                 } else {
        //                     return item;
        //                 }
        //             })
        //         });
        //         // 选过的规格不能再次选择
        //         let list = this.spuList.map((item) => {
        //             return item.templateName;
        //         });

        //         this.skuTemplateList = this.skuTemplateList.map((item) => {
        //             item.disabled = list.includes(item.templateName);
        //             return item;
        //         });
        //     }
        // },

        // 清除选择的模板
        // templateClear(index) {
        //     this.spuList[index].templateName = '';
        //     // 选过的规格不能再次选择
        //     let list = this.spuList.map((item) => {
        //         return item.templateName;
        //     });

        //     this.skuTemplateList = this.skuTemplateList.map((item) => {
        //         item.disabled = list.includes(item.templateName);
        //         return item;
        //     });
        // },

        // 删除模板
        // showTempPopover(item, index) {
        //     for (let i in this.skuTemplateList) {
        //         this.skuTemplateList[i].delTempVisible = false;
        //     }
        //     this.showTempIndex = index;
        //     item.delTempVisible = !item.delTempVisible;
        // },
        // delTemplateItem(item) {
        //     item.delTempVisible = false;
        // },

        // 获取SKU模板列表
        // getSkuTemplateList() {
        //     axios.get("/api/saas/s-good-sku/queryAll").then(res => {
        //         let templateNameList = this.spuList.map((item) => {
        //             return item.templateName;
        //         });
        //         this.skuTemplateList = res.map(item => {
        //             item.disabled = templateNameList.includes(item.templateName);
        //             return item
        //         });
        //     })
        // },

        // 聚焦
        skuInputFocus(index) {
            this.skuIndex = index;
        },

        /**
         * 将SPU列表中的选项一一组合，形成新的数组
         * @param {*} arr spu数组
         * @param {*} exchangeArr 是否已存在已经组合过的数组，如果不存在传空数组
         * @param {*} index spu标识
         */
        doExchange(arr, exchangeArr, index) {
            let resultList = [];
            let len = exchangeArr.length;
            let field = 'sku' + index;
            let spu_detail = arr[index].spu_detail;
            let childLen = arr[index].spu_detail.length;
            // 判断是否已经组合过了，没有直接循环push
            if (len === 0) {
                for (let i = 0; i < childLen; i++) {
                    resultList.push({
                        // [field]: spu_detail[i],
                        good_id: this.addGoodsData.good_id,
                        sku_id: uuid.v1(),
                        combination_id: spu_detail[i].spu_detail_id,
                        combination_name: spu_detail[i].spu_detail_name,
                        price: this.addGoodsData.price,
                        cost_price: this.addGoodsData.cost_price,
                        inventory: this.addGoodsData.inventory,
                        inventory: 0,
                        commodityCode: '',
                        isSelected: false,
                    });
                }
            } else if (len > 0) {
                // 如果组合过，修改原来的数据
                if (childLen > 0) {
                    for (let i = 0; i < len; i++) {
                        for (let j = 0; j < childLen; j++) {
                            let obj = { ...exchangeArr[i] };
                            obj.combination_id = obj.combination_id + '_' + spu_detail[j].spu_detail_id
                            obj.combination_name = obj.combination_name + '_' + spu_detail[j].spu_detail_name
                            resultList.push(obj)
                        }
                    }
                } else {
                    resultList = [...exchangeArr]
                }
            }

            // 将原来编辑的数据保存在新组合的数组中
            for (let i = 0; i < this.selectedSkuSetList.length; i++) {
                for (let j = 0; j < resultList.length; j++) {
                    let isEqual = true;
                    // 比较两个数据中的规格设置的值
                    if(resultList[j].combination_id === this.selectedSkuSetList[i].combination_id){
                        resultList[j] = { ...this.selectedSkuSetList[i] }
                    }
                }
            }

            index = index + 1;
            if (index < arr.length) {
                return this.doExchange(arr, resultList, index);
            } else {
                return resultList;
            }
        },

        // 删除SKU
        deleteSku(index) {
            this.spuList.splice(index, 1);
            if (this.spuList.length === 0) {
                this.skuList = [];
                // this.oldSkuSetTableList = [];
            } else {
                this.getSkuSetTableList();
            }
        },

        // 移除sku子列表
        handleClose(tag, index) {
            let name = this.spuList[index].spu_name;
            let isSameName = false;

            this.spuList.forEach((item, indexItem) => {
                if (indexItem !== index && item.spu_name.trim() === name.trim()) {
                    isSameName = true;
                }
            });

            if (isSameName) {
                this.$message.error('商品规格不能有相同项！');
                return;
            }

            this.spuList[index].spu_detail.splice(this.spuList[index].spu_detail.indexOf(tag), 1);

            this.getSkuSetTableList();
        },

        /**
         * 打开批量编辑弹窗
         * @param {number} type 1：价格，2：成本，3：库存
         */
        showEditDialog (type) {
            this.editType = type
            this.editNum = 0
            this.editDialog = true
        },
        confirmEdit () {
            if(!this.editNum && this.editNum !== 0){
                let msg = '请输入' +( this.editType === 1 ? '价格' : this.editType === 2 ? '成本' : '库存')
                this.$message.error(msg)
                return
            }
            this.skuList = this.skuList.map((item) => {
                if (this.editType === 1) {
                    item.price = this.editNum
                } else if (this.editType === 2) {
                    item.cost_price = this.editNum
                } else {
                    item.inventory = this.editNum
                }

                return item
            })
            this.$message.success('批量编辑成功！')
            this.editDialog = false
            
        },

        // 是否显示输入框
        showInput(index) {
            this.handleInputConfirm(index);
            this.$nextTick(() => {
                this.$refs['saveTagInput' + index][0].$refs.input.focus();
            });
        },

        // 确认新增子列表
        handleInputConfirm(index) {
            let name = this.spuList[index].spu_name;

            if (name === '') {
                this.$message.error('请先填写规格名称！');
                return;
            }

            let isSameName = false;
            this.spuList.forEach((item, indexItem) => {
                if (indexItem !== index && item.spu_name.trim() === name.trim()) {
                    isSameName = true;
                }
            });

            if (isSameName) {
                this.$message.error('商品规格不能有相同项！');
                return;
            }

            let inputValue = this.inputValue.trim();
            if (inputValue) {
                // 判断新增的值，是否已经存在
                let spu_detail = this.spuList[index].spu_detail
                let isExits = false

                spu_detail.forEach((item) => {
                    if (item.spu_detail_name.trim() === inputValue) {
                        isExits = true;
                    }
                })

                if (isExits) {
                    this.$message.error('已经存在相同的项')
                } else {
                    let len = this.spuList[index].spu_detail.length;
                    this.spuList[index].spu_detail.push({
                        spu_detail_id: uuid.v1(),
                        good_id: this.addGoodsData.good_id,
                        spu_id: this.spuList[index].spu_id,
                        spu_detail_name: inputValue,
                    });
                    this.getSkuSetTableList();
                    this.inputVisible = false;
                    this.inputValue = '';
                }
            } else {
                this.inputVisible = false;
                this.inputValue = '';
            }
        },

        // 重新组合规格列表
        getSkuSetTableList() {
            // 保存原来的组合数据
            this.oldSkuSetTableList = this.skuList.map(item => {
                return { ...item };
            });
            // 选中行
            this.skuList = [...this.doExchange(this.spuList, [], 0)];

            // 和原来的组合数据比较，如果组合相同，则保留原来填写的数据
            this.oldSkuSetTableList.forEach((oldItem) => {
                this.skuList = this.skuList.map((item) => {
                    if (this.isObjectValueEqual(oldItem, item)) {
                        return oldItem
                    } else {
                        return item;
                    }
                })
            });
        },

        // 判断两个商品对象的ID是否相等
        // 在SKU中不需要判断所有字段，只需要判断规格字段相等，即判断包含 ‘sku’的字段
        isObjectValueEqual(a, b) {
            // let aProps = Object.keys(a).filter((item) => {
            //     return item.indexOf('sku') !== -1
            // });
            // let bProps = Object.keys(b).filter((item) => {
            //     return item.indexOf('sku') !== -1
            // });

            // if (aProps.length !== bProps.length) {
            //     return false;
            // }
            // for (let i = 0; i < aProps.length; i++) {
            //     let propName = aProps[i];
            //     let propA = a[propName];
            //     let propB = b[propName];
            //     if (propA !== propB) {
            //         return false;
            //     }
            // }
            return a.combination_id === b.combination_id;
        },

        // 获取商品分组列表
        getGoodsGroupList() {
            this.axios.post('/good/group/list', {
                page: 1,
                size: 10000
            }).then((res) => {
                this.goodsGroupList = res.data.list
            })
        },

        // 获取商品分类列表
        getGoodsTypeList() {
            this.axios.post('/faction/query/all').then((res) => {
                this.factionList = res.data
            })
        },

        submitGoods(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.$route.query.id ? '/good/update' : '/good/add'
                    this.addGoodsData.spu = [...this.spuList];
                    this.addGoodsData.sku = [...this.skuList];
                    this.axios.post(requestUrl, this.addGoodsData).then((res) => {
                        this.$router.push('/home/goods')
                    }).catch(() => {
                    })
                } else {
                    return false
                }
            })
        },

        sureSaveGoodsGroup(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.post('/good/group/add', {
                        group_name: this.addGoodsGroupData.groupName
                    }).then((res) => {
                        console.log(res)
                        this.addGoodsGroupDialog = false
                        this.$message.success('成功添加商品分组')
                        this.getGoodsGroupList()
                        this.addGoodsGroupData.groupName = ''
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.add-goods-page {
    .title {
        padding: 14px;
        margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .el-input,
    .el-input-number {
        width: 260px;
    }

    .note {
        line-height: 20px;
        font-size: 14px;
        color: #d84040;
    }

    /*多个规格-规格名称*/
    .shop-sku {
        width: 800px;

        .sku-list-item {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            margin-bottom: 10px;

            .item-header {
                height: 50px;
                background-color: rgba(250, 250, 250, 1);
                line-height: 50px;
                padding: 0 10px;

                >.el-input,
                >.el-select {
                    width: 136px;
                    /*margin-right: 10px;*/
                    float: unset;
                }

                >span {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(74, 74, 74, 1);
                    line-height: 22px;
                    margin-right: 10px;
                }

                >.right-btn {
                    height: 50px;
                    float: right;
                    line-height: 50px;

                    >.el-button {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(74, 74, 74, 1);
                        border: unset;
                        /*line-height:20px;*/
                    }

                    .el-button.is-plain:focus,
                    .el-button.is-plain:hover {
                        border: unset;
                    }
                }
            }

            .child-list {
                padding: 0 10px;
                min-height: 50px;
            }
        }

        .add-sku {
            margin-top: 10px;
            height: 60px;
            text-align: center;
            color: #999999;
            line-height: 60px;
            background: rgba(0, 0, 0, 0.02);
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        .el-tag {
            margin-right: 10px;
        }

        .button-new-tag {
            margin-left: 10px;
        }

        .input-new-tag {
            width: 90px;
            vertical-align: bottom;
        }
    }

    /*多个规格-table*/
    .multiple-table {
        width: 98%;

        .imgList {
            .item {
                width: 40px;
                height: 40px;
                line-height: 38px;

                .imgwarp {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .img-item {
            width: 40px;
            height: 40px;
            line-height: 38px;

            .image-icon {
                font-size: 12px;
            }
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 160px;
        height: 160px;
        line-height: 160px;
        text-align: center;
    }

    .avatar {
        width: 160px;
        height: 160px;
        display: block;
    }

    .add-dialog {

        .el-input,
        .el-select {
            width: 240px;
        }

        .el-dialog__body {
            padding: 10px 20px;
        }
    }
}
</style>
